import React, { useEffect, useState } from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { useFormik } from "formik";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import { ClipLoader } from "react-spinners";

const ViewLeadInDetails = ({ modalOpen, setModalOpen, data }) => {
  const [isNotesLoading, setIsNotesLoading] = useState(false);
  const [allExistingLeadNotes, setAllExistingLeadNotes] = useState([]);

  useEffect(() => {
    getAllLeadNotes();
  }, [data]);

  const getAllLeadNotes = async () => {
    try {
      setIsNotesLoading(false);
      const string = QueryString.stringify({
        leadNumber: data ? data.leadNumber : "",
        sort: { createdAt: -1 },
      });
      const leadResp = await authAxiosInstance.get(`lead?${string}`);
      if (leadResp.data.data.docs.length > 0) {
        setAllExistingLeadNotes(leadResp.data.data.docs);
      } else {
        setAllExistingLeadNotes([]);
      }
      setIsNotesLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsNotesLoading(false);
    }
  };
  return (
    <ModalBasic setModalOpen={setModalOpen} modalOpen={modalOpen}>
      {isNotesLoading ? (
        <ClipLoader />
      ) : (
        <div className="p-4 flex flex-col gap-2 h-[390px] overflow-y-auto">
          <label className="underline">Notes</label>
          {allExistingLeadNotes.length > 0 &&
            allExistingLeadNotes.map((el) => (
              <div
                key={el._id}
                className="border p-2 w-full form-textarea  text-indigo-500 leading-6 bg-slate-100 tracking-wide"
              >
                {el?.notes ? el?.notes : <span className="text-slate-400">no notes added</span>}
              </div>
            ))}
        </div>
      )}
    </ModalBasic>
  );
};

export default ViewLeadInDetails;
