import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editProduct,
  fetchProduct,
  getProducts,
  resetProductData,
} from "../../app/reducers/Product/productSlice";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FieldArray, FormikProvider, useFormik } from "formik";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import ProductAttributes from "./ProductAttributes";
import { fetchAttributeTypes } from "../../app/reducers/AttributeType/attributeTypeSlice";
import { ClipLoader } from "react-spinners";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { fetchProductInventories } from "../../app/reducers/ProductInventory/productInventorySlice";
import { getAuth } from "../../app/reducers/Auth/authSlice";
import { toast } from "react-toastify";

const EditProduct = () => {
  const { id } = useParams();
  const { user } = useSelector(getAuth);
  const { elementEditData, editDataLoading } = useSelector(getProducts);

  const dispatch = useDispatch();

  const computeEditData = (data) => {
    const attributeArray = data?.attributes?.map((el, i) => ({
      type: el?.type?._id,
      attributeName: el?._id,
    }));
    return {
      ...data,
      attributes: attributeArray,
    };
  };

  useEffect(() => {
    dispatch(fetchProduct({ _id: id, populate: true }));
    dispatch(fetchAttributeTypes());
    return () => {
      dispatch(resetProductData());
    };
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: computeEditData(elementEditData),
    onSubmit: async (values) => {
      // try {
      //   const attributeIds = values?.attributes?.map((el) => el?.attributeName);
      //   const payload = {
      //     ...values,
      //     attributes: attributeIds,
      //   };

      //   if (values.manageStock === "no") {
      //     const isProductInventoryExist = await dispatch(
      //       fetchProductInventories({
      //         product: values?._id,
      //         organization: user?.organization,
      //       })
      //     );

      //     if (isProductInventoryExist.payload.data.docs.length > 0) {
      //       return toast.error("Stock is found in inventory");
      //     } else {
      //       await dispatch(editProduct(payload));
      //       await dispatch(fetchProduct({ _id: id, populate: true }));
      //     }
      //   } else {
      //     await dispatch(editProduct(payload));
      //     await dispatch(fetchProduct({ _id: id, populate: true }));
      //   }
      // } catch (error) {
      //   console.log(error, "error");
      //   toast.error("Failed to edit Product");
      // }

      try {
        const attributeIds = values?.attributes?.map((el) => el?.attributeName);
        const payload = { ...values, attributes: attributeIds };

        const updateProduct = async () => {
          await dispatch(editProduct(payload));
          await dispatch(fetchProduct({ _id: id, populate: true }));
        };

        if (values.manageStock === "no") {
          const isProductInventoryExist = await dispatch(
            fetchProductInventories({
              product: values?._id,
              organization: user?.organization,
            })
          );

          const hasInventory =
            isProductInventoryExist.payload.data.docs.length > 0;
          if (hasInventory) {
            return toast.error("Stock is found in inventory");
          }
        }

        await updateProduct();
      } catch (error) {
        console.log(error, "error");
        toast.error("Failed to edit Product");
      }
    },
  });

  return (
    <PageWithCard heading="Edit Product">
      {editDataLoading ? (
        <ClipLoader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
          <FormikInputGroup label="Name" name="name" formik={formik} required />

          {formik?.values?.name && (
            <FormikProvider value={formik}>
              <FieldArray
                name="attributes"
                render={(arrayHelpers) => {
                  return (
                    <div className="flex flex-col gap-2 border border-gray-200 p-2 rounded-md">
                      <h1 className="text text-lg font-bold">Add Attributes</h1>
                      <div>
                        {formik.values.attributes.map((ele, index) => (
                          <div
                            className="relative p-4 mb-2"
                            style={{
                              border: "1px solid #d6c7c7",
                              borderRadius: "5px",
                            }}
                            key={index}
                          >
                            {/* This component is common for AddProduct and EditProduct */}
                            <ProductAttributes
                              formik={formik}
                              index={index}
                              arrayHelpers={arrayHelpers}
                            />
                          </div>
                        ))}
                      </div>
                      <div>
                        <SecondaryButton
                          onClick={() => {
                            arrayHelpers.push({});
                          }}
                          type="button"
                        >
                          Add More
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          )}

          {formik?.values?.name && (
            <FormikDirectFileUpload
              formik={formik}
              label="Upload Photo"
              name="photo"
              location={`product/${formik?.values?.name}/`}
              required
            />
          )}

          <FormikInputGroup
            label="Cost Price"
            name="costPrice"
            formik={formik}
            required
          />

          <FormikInputGroup
            type="number"
            label="MRP"
            name="MRP"
            formik={formik}
            required
          />
          <FormikInputGroup
            type="number"
            label="Selling Price"
            name="sellingPrice"
            formik={formik}
            required
          />
          <FormikInputGroup
            type="number"
            label="Discount"
            name="discount"
            formik={formik}
            value={
              Number(formik.values?.MRP) - Number(formik?.values?.sellingPrice)
            }
            readOnly
            required
          />

          <FormikInputGroup
            label="Master Sku"
            name="masterSku"
            formik={formik}
            required
          />
          <FormikInputGroup
            label="Tax Rate"
            name="tax_rate"
            formik={formik}
            required
          />
          <FormikSelectGroup
            label="Tax Type"
            name="tax_type"
            formik={formik}
            options={[
              { label: "Inclusive", value: "inclusive" },
              { label: "Exclusive", value: "exclusive" },
              { label: "N/A", value: "n/a" },
            ]}
            required
          />
          <FormikInputGroup
            label="HSN Code"
            name="HSN_code"
            formik={formik}
            required
          />
          <FormikInputGroup
            type="number"
            label="Notification Quantity"
            name="notificationQuantity"
            formik={formik}
            required
          />
          <FormikSelectGroup
            label="Activate Notification"
            name="activateNotification"
            formik={formik}
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
            required
          />
          <FormikSelectGroup
            label="Manage stock"
            name="manageStock"
            formik={formik}
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
            required
          />
          {formik?.values?.masterSku && (
            <FormikProvider value={formik}>
              <FieldArray
                name="additionalSku"
                render={(arrayHelpers) => {
                  return (
                    <div className="flex flex-col gap-2 border border-gray-200 p-2 rounded-md">
                      <h1 className="text text-lg font-bold">
                        Add Additional Sku
                      </h1>
                      <div>
                        {formik.values.additionalSku.map((ele, index) => (
                          <div
                            className="relative p-4 mb-2"
                            style={{
                              border: "1px solid #d6c7c7",
                              borderRadius: "5px",
                            }}
                            key={index}
                          >
                            <div
                              className="relative p-4 mb-2"
                              style={{
                                border: "1px solid #d6c7c7",
                                borderRadius: "5px",
                              }}
                            >
                              <FormikInputGroup
                                label="Platform"
                                formik={formik}
                                name={`additionalSku.${index}.platform`}
                                required
                              />
                              <FormikInputGroup
                                label="Sku"
                                formik={formik}
                                name={`additionalSku.${index}.sku`}
                                required
                              />
                            </div>
                            <div>
                              <DangerButton
                                className="mt-3"
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                                type="button"
                              >
                                Remove
                              </DangerButton>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div>
                        <SecondaryButton
                          onClick={() => {
                            arrayHelpers.push({});
                          }}
                          type="button"
                        >
                          Add More
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          )}
          <div>
            {formik.isSubmitting ? (
              <ClipLoader />
            ) : (
              <PrimaryButton type="submit">Submit</PrimaryButton>
            )}
          </div>
        </form>
      )}
    </PageWithCard>
  );
};
export default EditProduct;
