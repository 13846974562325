import { useEffect, useMemo, useState } from 'react';
import FormikMultiSelect from '../components/formik/FormikMultiSelect';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations, getLocation } from '../app/reducers/Location/locationSlice';
import { useFormik } from 'formik';
import { authAxiosInstance } from '../utils/axiosConfig';
import QueryString from 'qs';
import { generateOptions } from '.././utils/Utils';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import TableWithHeadingAndSearch from '.././components/Table/TableWithHeadingAndSearch';
import UniversalSearch from './UniversalSearch/UniversalSearch';
import ShowDropdown from '../components/infrastructure/ShowDropdown';
import moment from 'moment';
import { ArrowUpRight, Edit, Eye, Mail } from 'react-feather';
import whatsappIcon from '../images/whatsapp_PNG.png';
import MessageTextModal from './Leads/MessageTextModal';
import ViewLeadInDetails from './Leads/ViewLeadInDetails';

const Dashboard = () => {
  const [linkOption, setLinkOption] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openMessageBox, setOpenMessageBox] = useState(false);
  const [leadNotesData, setLeadNotesData] = useState({});
  const [isLeadNotesModalOpen, setIsLeadNotesModalOpen] = useState(false);

  const [productPendingBalance, setProductPendingBalance] = useState([]);
  const [productInventoryTotalReceive, setProductInventoryTotalReceive] = useState([]);
  const [productInventoryTotalDamaged, setProductInventoryTotalDamaged] = useState([]);
  const [productInventoryTotalProductValuation, setProductInventoryTotalProductValuation] =
    useState([]);
  const [productInventoryParentSkuWiseData, setProductInventoryParentSkuWiseData] = useState([]);
  const [totalSumOfSalesProduct, setTotalSumOfSalesProduct] = useState({});
  const [totalSalesValuation, setTotalSalesValuation] = useState({});
  const [totalSumOfSalesReturnProduct, setTotalSumOfSalesReturnProduct] = useState({});
  const [totalSalesReturnValuation, setTotalSalesReturnValuation] = useState({});
  const [todayMeetingData, setTodayMeetingData] = useState({});
  const [weekMeetingData, setWeekMeetingData] = useState({});

  const { location, loading } = useSelector(getLocation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationInfold = JSON.parse(localStorage.getItem('locationInfoId'));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { location: locationInfold ?? [] },
    onSubmit: () => {},
  });

  const locationData = useMemo(
    () => (location?.docs ? location.docs : []),
    [location, locationInfold]
  );

  useEffect(() => {
    getDashboardTotalSaleCount();
    getDashboardTotalSaleReturnCount();
    getDashboardCount();
    dispatch(fetchLocations());
    getTodayMeeting();
    getWeekMeetings();
  }, [JSON.stringify(formik?.values?.location)]);

  const getTodayMeeting = async () => {
    try {
      setIsLoading(true);
      const startOfDay = moment().startOf('day').valueOf(); // Start of today in milliseconds
      const endOfDay = moment().endOf('day').valueOf(); // End of today in milliseconds
      const string = QueryString.stringify({
        isMeetingSchedule: 'yes',
        meetingDate: {
          $gte: startOfDay, // Start of today in milliseconds
          $lte: endOfDay, // End of today in milliseconds
        },
        populate: true,
        editStatus: 'approved',
      });

      const resp = await authAxiosInstance.get(`lead?${string}`);

      if (resp.data.data.docs.length > 0) {
        setTodayMeetingData(resp.data.data);
      } else {
        setTodayMeetingData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWeekMeetings = async () => {
    try {
      setIsLoading(true);

      const startOfDay = moment().startOf('day').valueOf(); // Start of today in milliseconds
      const endOfWeek = moment().add(7, 'days').endOf('day').valueOf(); // End of the 7th day in milliseconds

      const string = QueryString.stringify({
        isMeetingSchedule: 'yes',
        meetingDate: {
          $gte: startOfDay, // Start of today in milliseconds
          $lte: endOfWeek, // End of the week in milliseconds
        },
        populate: true,
        editStatus: 'approved',
      });

      const resp = await authAxiosInstance.get(`lead?${string}`);

      if (resp.data.data.docs.length > 0) {
        setWeekMeetingData(resp.data.data);
      } else {
        setWeekMeetingData({});
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDashboardCount = async () => {
    try {
      setIsLoading(true);
      const filterQuery = QueryString.stringify({
        filteredLocationArray: formik?.values?.location,
      });

      const response = await authAxiosInstance.get(`dashboard?${filterQuery}`);
      if (response) {
        setProductPendingBalance(response?.data?.data?.productPendingBalance);
        setProductInventoryTotalReceive(response?.data?.data?.productInventoryTotalReceivedData);
        setProductInventoryTotalDamaged(response?.data?.data?.productInventoryTotalDamagedData);
        setProductInventoryTotalProductValuation(
          response?.data?.data?.productInventoryTotalProductValuationData
        );
        setProductInventoryParentSkuWiseData(
          response?.data?.data?.productInventoryTotalParentSkuWiseValuationData
        );
      } else {
        setProductPendingBalance([]);
        setProductInventoryTotalReceive([]);
        setProductInventoryTotalDamaged([]);
        setProductInventoryTotalProductValuation([]);
        setProductInventoryParentSkuWiseData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error('Failed to Get Dashboard Data');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDashboardTotalSaleCount = async () => {
    try {
      setIsLoading(true);
      let filterSales = {
        location: { $in: formik.values.location },
        createdAt: {
          $gt: moment().startOf('day').valueOf(),
          $lt: moment().endOf('day').valueOf(),
        },
      };
      const query = QueryString.stringify(filterSales);
      const responseCount = await authAxiosInstance.get(`dashboard/sale-count?${query}`);

      const responseValuation = await authAxiosInstance.get(`dashboard/sale-valuation?${query}`);

      if (responseCount || responseValuation) {
        setTotalSumOfSalesProduct(responseCount?.data?.data?.docs[0]);
        setTotalSalesValuation(responseValuation?.data?.data?.docs[0]);
      } else {
        setTotalSumOfSalesProduct({});
        setTotalSalesValuation({});
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error('Failed to Get Total Sales');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDashboardTotalSaleReturnCount = async () => {
    try {
      setIsLoading(true);
      let filterSales = {
        location: { $in: formik.values.location },
        createdAt: {
          $gt: moment().startOf('day').valueOf(),
          $lt: moment().endOf('day').valueOf(),
        },
      };
      const query = QueryString.stringify(filterSales);
      const responseCount = await authAxiosInstance.get(`dashboard/sale-return-count?${query}`);

      const responseValuation = await authAxiosInstance.get(
        `dashboard/sale-return-valuation?${query}`
      );

      if (responseCount || responseValuation) {
        setTotalSumOfSalesReturnProduct(responseCount?.data?.data?.docs[0]);
        setTotalSalesReturnValuation(responseValuation?.data?.data?.docs[0]);
      } else {
        setTotalSumOfSalesReturnProduct({});
        setTotalSalesReturnValuation({});
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error('Failed to Get Total Sales Return');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: 'Parent Sku',
      Cell: ({ row }) => {
        return (
          <div
            style={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={() => {
              const string = QueryString.stringify({
                location: formik.values.location,
              });
              navigate(`/dashboard/viewProductByParentSku/${row?.original?._id}?${string}`);
            }}
          >
            {row?.original?._id}
          </div>
        );
      },
      accessor: '_id',
    },

    {
      Header: 'Received',
      accessor: 'sumOfTotalReceived',
    },
    {
      Header: 'Damaged',
      accessor: 'sumOfTotalDamaged',
    },
  ];

  const todayMeetingCol = [
    {
      Header: 'Lead Number',
      accessor: 'leadNumber',
      cellStyle: {
        width: '20%',
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      cellStyle: {
        width: '20%',
      },
    },
    {
      Header: 'Phone Numbers',
      accessor : 'contactNumbers',
      Cell: ({ row }) => {
        return (
          <ul>
            {row?.original?.contactNumbers?.map(contactNumber => (
              <li>{contactNumber}</li>
            ))}
          </ul>
        );
      },
      cellStyle: {
        width: '20%',
      },
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      Cell: ({ row }) => {
        return (
          <div className="flex gap-2 w-[170px]">
            <div
              className="relative group cursor-pointer"
              onClick={e => {
                e.stopPropagation();
                setIsLeadNotesModalOpen(true);
                setLeadNotesData(row.original);
              }}
            >
              <p className="text text-wrap line-clamp-4 w-full flex flex-row hover:text-indigo-500 items-center justify-between group">
                {row.original.notes}
                <span>
                <ArrowUpRight size={18} className=" text-slate-400 hover:text-indigo-500 group-hover:text-indigo-500" />
                </span>
              </p>
              <div className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 hidden group-hover:block ">
                <div className="bg-gray-400 text-white text-xs rounded py-1 px-2 whitespace-nowrap ">
                  Click To View Full Notes
                </div>
                <div className="w-2.5 h-2.5 bg-gray-400 transform rotate-45 absolute top-1/2 -translate-y-1/2 -left-1.5"></div>
              </div>
            </div>
          </div>
        );
      },
      cellStyle: {
        width: "100%",
      },
    },

    {
      Header: 'Meeting Date',
      Cell: ({ row }) => {
        return (
          <span>
            {row?.original?.meetingDate
              ? moment(row?.original?.meetingDate).format('DD/MM/YYYY')
              : null}
          </span>
        );
      },
      cellStyle: {
        width: '20%',
      },
    },
    {
      Header: 'Meeting Time',
      Cell: ({ row }) => {
        return (
          <span>
            {row?.original?.meetingTime
              ? moment(row?.original?.meetingTime).format('hh:mm a')
              : null}
          </span>
        );
      },
      cellStyle: {
        width: '20%',
      },
    },
    {
      Header: 'Products',
      Cell: ({ row }) => {
        return (
          <ul>
            {row?.original?.product?.map(n => (
              <li key={n?._id}>{n.name}</li>
            ))}
          </ul>
        );
      },
      accessor: "product.name",
      cellStyle: {
        width: '20%',
      },
    },
    {
      Header: 'Reference',
      Cell: ({ row }) => {
        return <span>{row?.original?.reference?.name}</span>;
      },
      cellStyle: {
        width: '20%',
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center gap-2">
            <Edit
              size={22}
              color="#292ba9"
              className="cursor-pointer"
              onClick={async e => {
                e.stopPropagation();
                navigate(`/lead/edit/${row?.original?._id}`);
              }}
            />
            <Eye
              size={22}
              color="#408bff"
              className="cursor-pointer"
              onClick={async e => {
                e.stopPropagation();
                navigate(`/leadHistory/${row?.original?.leadNumber}`);
              }}
            />
            <span
              onClick={async e => {
                e.stopPropagation();
                console.log('row', row?.original);
                const template = 'Hello, {#var#}, We have a meeting';
                const message = replacePlaceholder(template, [`${row?.original?.name}`]);
                const linkOption = {
                  phone: `${row?.original?.contactNumbers[0]}`,
                  text: message,
                };
                setLinkOption(linkOption);
                setOpenMessageBox(true);
              }}
            >
              <img src={whatsappIcon} alt="Whatsapp" className="w-[30px] h-[20px]" />
            </span>
            <Mail
              size={22}
              color="#c82a2a"
              className="cursor-pointer"
              onClick={async e => {
                e.stopPropagation();
                const subject = encodeURIComponent('Hello');
                const body = encodeURIComponent('I would like to inquire about...');
                const mailtoLink = `mailto:${row?.original?.emails[0]}?subject=${subject}&body=${body}`;
                window.location.href = mailtoLink;
              }}
            />
          </div>
        );
      },
      cellStyle: {
        width: '40%',
      },
    },
  ];

  const replacePlaceholder = (inputStr, replacementArray) => {
    let replacedStr = inputStr;

    for (let i = 0; i < replacementArray.length; i++) {
      replacedStr = replacedStr.replace('{#var#}', replacementArray[i]);
    }

    return replacedStr;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="mb-8">
        {/* Title */}
        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Dashboard</h1>
      </div>
      <MessageTextModal
        linkOption={linkOption}
        openMessageBox={openMessageBox}
        setOpenMessageBox={setOpenMessageBox}
      />
      <ViewLeadInDetails
        modalOpen={isLeadNotesModalOpen}
        setModalOpen={setIsLeadNotesModalOpen}
        data={leadNotesData}
      />

      {/* Content */}

      {loading || isLoading ? (
        <ClipLoader />
      ) : (
        <div className="mx-8">
          <FormikMultiSelect
            label="Select Location"
            name="location"
            formik={formik}
            options={generateOptions({
              array: locationData ?? [],
              valueField: '_id',
              labelField: 'name',
            })}
          />
        </div>
      )}

      <div className="bg-white  rounded-sm mb-8">
        <div className="grid w-full grid-cols-1 sm:grid-cols-2 xl:grid-cols-3  justify-center sm:justify-around p-2 sm:p-8 items-center gap-2">
          {/* // */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Product Pending</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productPendingBalance &&
                  productPendingBalance.length &&
                  productPendingBalance.map((el, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div
                        key={i}
                        style={{
                          textDecoration: 'underline',
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                        onClick={() => navigate(`/dashboard/viewVendorWisePurchaseOrder`)}
                      >
                        {el?.sumOfCurrentProductPendingBalance}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* // */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Total Product Received</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productInventoryTotalReceive &&
                  productInventoryTotalReceive.length &&
                  productInventoryTotalReceive.map((el, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{el?.productTotalInventoryReceived}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* // */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Damaged Product</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productInventoryTotalDamaged &&
                  productInventoryTotalDamaged.length &&
                  productInventoryTotalDamaged.map((el, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{el?.productTotalInventoryDamaged}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* // */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Inventory Valuation</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productInventoryTotalProductValuation &&
                  productInventoryTotalProductValuation.length &&
                  productInventoryTotalProductValuation.map((el, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{el?.totalInventoryProductValuation}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* totalSale */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Today's Sales</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSumOfSalesProduct ? (
                  <div className="flex-col items-center text-lg">
                    <div>{totalSumOfSalesProduct?.totalTodayQty}</div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>
          {/* totalSale Valuation */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Today's Sales Valuation</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSalesValuation ? (
                  <div className="flex-col items-center text-lg">
                    <div>{totalSalesValuation?.totalValuation}</div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>

          {/* totalSale return */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Today's Return</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSumOfSalesReturnProduct ? (
                  <div className="flex-col items-center text-lg">
                    <div>{totalSumOfSalesReturnProduct?.totalTodayQty}</div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>
          {/* totalSale return Valuation */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Today's Return Valuation</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSalesReturnValuation ? (
                  <div className="flex-col items-center text-lg">
                    <div>{totalSalesReturnValuation?.totalValuation}</div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>
          {/* net sale =sale valuation-return valuation */}
          <div className="max-w-sm w-full sm:w-[300px]  border overflow-hidden shadow-lg text-center mb-3 rounded-lg cursor-pointer">
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Today's Net Sales</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {totalSalesReturnValuation || totalSalesValuation ? (
                  <div className="flex-col items-center text-lg">
                    <div>
                      {totalSalesValuation
                        ? totalSalesValuation?.totalValuation
                        : 0 - totalSalesReturnValuation
                        ? totalSalesReturnValuation?.totalValuation
                        : 0}
                    </div>
                  </div>
                ) : (
                  0
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {productInventoryParentSkuWiseData && (
        <div className=" mx-8 mb-4">
          <ShowDropdown allowedRoles={['admin', 'org_admin']} heading="Inventory">
            <TableWithHeadingAndSearch
              columns={columns}
              data={productInventoryParentSkuWiseData}
              heading="Inventory"
            />
          </ShowDropdown>
        </div>
      )}

      {/* todayMeeting */}
      {todayMeetingData?.docs?.length > 0 && (
        <div className=" mx-8">
          <TableWithHeadingAndSearch
            columns={todayMeetingCol}
            data={todayMeetingData?.docs}
            heading="Today's Meeting Schedule"
          />
        </div>
      )}

      {/* todayMeeting */}
      {weekMeetingData?.docs?.length > 0 && (
        <div className=" mx-8 mt-3">
          <TableWithHeadingAndSearch
            columns={todayMeetingCol}
            data={weekMeetingData?.docs}
            heading="Week's Meeting Schedule"
          />
        </div>
      )}

      {/* imported Universal Search */}
      <UniversalSearch />
    </div>
  );
};

export default Dashboard;
